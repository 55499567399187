
import { getUserCompanies } from "@/helpers/companies";
import {
  quoteMapActions,
  quoteMapState,
  quoteMapMutations
} from "@/store/modules/quote";
import Vue from "vue";
import { get } from "lodash";
import DisplayData from "@/components/DataView/DisplayData.vue";
import DisplayLoopingData from "@/components/DataView/DisplayLoopingData.vue";
import {
  numberOfNonWeatherClaimsOptions,
  paymentPlanOptions
} from "@/forms/shared/quote.home_owner";
import {
  yearBuiltOptions,
  oldCompanies,
  occupancyOptions,
  billingPlanOptions
} from "@/helpers/selectOptions";
import { downloadQuoteDocument } from "@/helpers/downloadLossForm";
import * as types from "@/store/mutation-types";
import { authMapGetters } from "@/store/modules/auth";
import { getPHPAPIFormattedDate } from "@/helpers/dateProcessor";
import {
  dwellingTypeOptions,
  propertyUsageOptions,
  structureTypeOptions
} from "@/forms/shared/quote.dwelling_fire";
import {
  tenantDwellingOptions,
  tenantStructureTypeOptions
} from "@/forms/shared/quote.tenant";
import { stringifyAddress } from "@/helpers/stringifyAddress";

interface Idata {
  didInit: boolean;
  error: string;
  resourceNotFound: boolean;
  resourceNotFoundMsg: string;
  expanded: boolean;
  rate: any;
  rating: any;
  questions: any;
  selectedStatus: string;
}
export default Vue.extend({
  name: "ViewSingleQuote",
  components: {
    DisplayData,
    DisplayLoopingData,
    CustomAlert: () => import("@/components/CustomAlert/CustomAlert.vue")
  },
  props: {
    quoteId: {
      type: String,
      required: true
    },
    isLegacy: {
      type: Boolean,
      default: false
    }
  },
  data(): Idata {
    return {
      didInit: false,
      error: "",
      resourceNotFound: false,
      resourceNotFoundMsg:
        "Ooops! The quote you are looking for cannot be found.",
      expanded: true,
      rate: {},
      rating: {},
      questions: {},
      selectedStatus: ""
    };
  },
  mounted() {
    this.initializePageData();
  },
  methods: {
    ...quoteMapActions(["getQuote", "updateQuote"]),
    ...quoteMapMutations({
      editField: types.SET_EDIT_FIELD
    }),
    getFormattedDate() {
      return getPHPAPIFormattedDate(
        new Date(get(this.editing, "effectiveDate"))
      );
    },
    async initializePageData(): Promise<void> {
      try {
        await this.getQuote(this.quoteId);
        this.selectedStatus =
          this.editing && this.editing.status ? this.editing.status : "";
        this.didInit = true;

        let query: { appliesTo: string; companyNumber?: number } = {
          appliesTo: this.editing.policyType
        };
        if (this.editing.companyNumbers) {
          query["companyNumber"] = this.editing.companyNumbers;
        }
      } catch (error) {
        this.resourceNotFound = true;
        this.error = error.message;
        this.$bugSnagClient.notify(error);
      }
    },
    getYear(year: number): string {
      let options = yearBuiltOptions.find(
        (option: any) => option.value === year
      );
      return options?.label || "";
    },
    getNumberOfNonWeatherClaims(numberOfNonWeatherClaims: number): string {
      let options = numberOfNonWeatherClaimsOptions.find(
        option => option.value === numberOfNonWeatherClaims
      );
      return options?.label || "";
    },
    getCompany(data: any): string | undefined {
      const companyNumber = get(data, "companyNumbers[0]", "");

      let companyName = "";
      if (
        this.userCompanies instanceof Array &&
        this.userCompanies.length > 0
      ) {
        let options = this.userCompanies.find(
          option => option.value === companyNumber
        );
        companyName = options?.label || "";
      }
      const oldCompanyNumbers = oldCompanies.map(company => company.value);
      if (
        this.editing.isLegacy &&
        oldCompanyNumbers.includes(companyNumber.toString())
      ) {
        const company = oldCompanies.find(
          option => option.value == companyNumber
        );
        if (company) {
          companyName = company.label;
        }
      }
      return companyName;
    },
    async printQuote() {
      await downloadQuoteDocument({
        quoteIds: [this.editing._id],
        rateId: this.editing.ratingSelected
      });
    },
    onToolbarItemClick(event: string) {
      switch (event) {
        case "print":
          this.printQuote();
          break;
      }
    },
    getPropertyUsage(propertyUsage: string): string {
      let options = propertyUsageOptions.find(
        option => option.value === propertyUsage
      );
      return options?.label || "";
    },
    getDwellingType(dwellingType: string, policyType: string): string {
      let options;
      if (policyType === "T") {
        options = tenantDwellingOptions.find(
          option => option.value === dwellingType
        );
      } else {
        options = dwellingTypeOptions.find(
          option => option.value === dwellingType
        );
      }
      return options?.label || "";
    },
    getStructureType(structureType: string, policyType: string): string {
      let options;
      if (policyType === "T") {
        options = tenantStructureTypeOptions.find(
          option => option.value === structureType
        );
      } else {
        options = structureTypeOptions.find(
          option => option.value === structureType
        );
      }
      return options?.label || "";
    },
    getOccupancy(occupancy: string): string {
      let options = occupancyOptions.find(option => option.value === occupancy);
      return options?.label || "";
    },
    getPaymentPlan(paymentCode: string): string {
      let options = paymentPlanOptions.find(
        option => option.value === paymentCode
      );
      return options?.label || "";
    },
    getBillingPlan(billingCode: string): string {
      let options = billingPlanOptions.find(
        option => option.value === billingCode
      );
      return options?.label || "";
    }
  },
  computed: {
    ...quoteMapState(["makingApiRequest", "editing"]),
    ...authMapGetters(["getCurrentUser"]),
    userCompanies(): any[] {
      let companies = getUserCompanies("legacyQuote");
      return companies;
    },
    userRole(): string {
      return get(this.getCurrentUser, "role", "");
    },
    getPolicyType(): string {
      if (this.editing.policyType === "H") {
        return "Homeowner";
      } else if (this.editing.policyType === "D") {
        return "Dwelling Fire";
      } else if (this.editing.policyType === "T") {
        return "Tenant/Condo";
      } else {
        return "N/A";
      }
    },
    getData(): any {
      let results = {};
      results = {
        sections: [
          {
            sectionTitle: "Property Usage & Construction",
            showSection: this.editing.policyType !== "T",
            showDivider: true,
            sectionColumnSize: "6",
            information: [
              {
                informationColumnSize: "2",
                key: "Property Usage",
                isArray: false,
                showColumn: !!this.editing.useAge,
                value: this.getPropertyUsage(get(this.editing, "useAge"))
              },
              {
                informationColumnSize: "2",
                key: "Dwelling Type",
                isArray: false,
                showColumn: !!this.editing.dwellType,
                value: this.getDwellingType(
                  get(this.editing, "dwellType"),
                  this.editing.policyType
                )
              },
              {
                informationColumnSize: "2",
                key: "Building Structure Type",
                isArray: false,
                showColumn: !!this.editing.structureType,
                value: this.getStructureType(
                  get(this.editing, "structureType"),
                  this.editing.policyType
                )
              }
            ]
          },
          {
            sectionTitle: "Property Usage & Construction",
            showSection: this.editing.policyType === "T",
            showDivider: false,
            sectionColumnSize: "6",
            information: [
              {
                informationColumnSize: "2",
                key: "Dwelling Type",
                isArray: false,
                showColumn: !!this.editing.dwellType,
                value: this.getDwellingType(
                  get(this.editing, "dwellType"),
                  this.editing.policyType
                )
              },
              {
                informationColumnSize: "2",
                key: "Building Structure Type",
                isArray: false,
                showColumn: !!this.editing.structureType,
                value: this.getStructureType(
                  get(this.editing, "structureType"),
                  this.editing.policyType
                )
              }
            ]
          },
          {
            sectionTitle: "",
            showSection:
              this.editing &&
              this.editing.policyType === "H" &&
              this.editing.occupancy,
            showDivider: false,
            sectionColumnSize: "6",
            information: [
              {
                informationColumnSize: "2",
                key: "Occupancy",
                isArray: false,
                showColumn: !!this.editing.occupancy,
                value: this.getOccupancy(get(this.editing, "occupancy"))
              }
            ]
          },
          {
            sectionTitle: "",
            showSection: this.editing && !!this.editing.paymentPlan,
            showDivider: false,
            sectionColumnSize: "6",
            information: [
              {
                informationColumnSize: "2",
                key: "Payment Plan",
                isArray: false,
                showColumn: !!this.editing.paymentPlan,
                value: this.getPaymentPlan(get(this.editing, "paymentPlan"))
              },
              {
                informationColumnSize: "2",
                key: "Payment Plan",
                showColumn:
                  get(this.editing, "paymentPlan") === "1" &&
                  get(this.editing, "billing"),
                isArray: false,
                value: this.getBillingPlan(get(this.editing, "billing"))
              }
            ]
          },
          {
            sectionTitle: "Dwelling Information",
            showSection:
              this.editing.policyType === "D" ||
              this.editing.policyType === "H",
            showDivider: true,
            sectionColumnSize: "6",
            information: [
              {
                informationColumnSize: "2",
                key: "Year Built",
                isArray: false,
                showColumn: !!this.editing.yearBuild,
                value: this.getYear(get(this.editing, "yearBuild"))
              },
              {
                informationColumnSize: "1",
                key: "Building Amount",
                isArray: false,
                showColumn: !!this.editing.amountDWP,
                value: get(this.editing, "amountDWP")
              },
              {
                informationColumnSize: "1",
                key: "Contents Amount",
                isArray: false,
                showColumn: !!this.editing.amountUPP,
                value: get(this.editing, "amountUPP")
              }
            ]
          },
          {
            sectionTitle: "Dwelling Information",
            showSection: this.editing.policyType === "T",
            showDivider: true,
            sectionColumnSize: "8",
            information: [
              {
                informationColumnSize: "2",
                key: "Year Built",
                isArray: false,
                showColumn: !!this.editing.yearBuild,
                value: this.getYear(get(this.editing, "yearBuild"))
              },
              {
                informationColumnSize: "2",
                key: "Contents Amount",
                isArray: false,
                showColumn: !!this.editing.amountUPP,
                value: get(this.editing, "amountUPP")
              }
            ]
          },
          {
            sectionTitle: "",
            showSection: true,
            showDivider: false,
            sectionColumnSize: "4",
            information: [
              {
                informationColumnSize: "2",
                key: "Number of Non-Weather or Non-Appliance Claims",
                isArray: false,
                showColumn: !!this.editing.numberOfNonWeatherClaims,
                value: this.getNumberOfNonWeatherClaims(
                  get(this.editing, "numberOfNonWeatherClaims")
                )
              }
            ]
          }
        ]
      };
      return results;
    },
    getApplicantsData(): any {
      let results = {};
      results = {
        sections: [
          {
            sectionTitle: "Applicant Information",
            showSection: true,
            showDivider: false,
            sectionColumnSize: "8",
            information: [
              {
                informationColumnSize: "2",
                key: "First Name",
                isArray: false,
                showColumn: !!this.editing.clientFirstName,
                value: get(this.editing, "clientFirstName", "")
              },
              {
                informationColumnSize: "2",
                key: "Last Name",
                isArray: false,
                showColumn: !!this.editing.clientLastName,
                value: get(this.editing, "clientLastName", "")
              },
              {
                informationColumnSize: "2",
                key: "Email",
                isArray: false,
                showColumn: !!this.editing.clientEmail,
                value: get(this.editing, "clientEmail", "")
              }
            ]
          },
          {
            sectionTitle: "",
            showSection: true,
            showDivider: false,
            sectionColumnSize: "8",
            information: [
              {
                informationColumnSize: "2",
                key: "Date of Birth",
                isArray: false,
                showColumn:
                  this.editing &&
                  this.editing.quoteApplication &&
                  this.editing.quoteApplication.applicant &&
                  !!this.editing.quoteApplication.applicant.dateOfBirth,
                value:
                  this.editing &&
                  this.editing.quoteApplication &&
                  this.editing.quoteApplication.applicant &&
                  this.editing.quoteApplication.applicant.dateOfBirth
                    ? getPHPAPIFormattedDate(
                        new Date(
                          get(
                            this.editing,
                            "quoteApplication.applicant.dateOfBirth"
                          )
                        )
                      )
                    : ""
              },
              {
                informationColumnSize: "2",
                key: "Marital Status",
                isArray: false,
                showColumn:
                  this.editing &&
                  this.editing.quoteApplication &&
                  this.editing.quoteApplication.applicant &&
                  !!this.editing.quoteApplication.applicant.maritalStatus,
                value: get(
                  this.editing,
                  "quoteApplication.applicant.maritalStatus",
                  ""
                )
              }
            ]
          },
          {
            sectionTitle: "",
            showSection: true,
            showDivider: false,
            sectionColumnSize: "8",
            information: [
              {
                informationColumnSize: "2",
                key: "Underwriter's code",
                isArray: false,
                showColumn:
                  this.editing &&
                  this.editing.quoteApplication &&
                  !!this.editing.quoteApplication.underwritersCode,
                value: get(
                  this.editing,
                  "quoteApplication.underwritersCode",
                  ""
                )
              }
            ]
          },
          {
            sectionTitle: "Risk Address",
            showSection: this.editing && this.editing.riskAddress,
            showDivider: true,
            sectionColumnSize: "8",
            information: [
              {
                informationColumnSize: "8",
                key: "",
                isArray: false,
                showColumn: !!this.editing && !!this.editing.riskAddress,
                value: `${stringifyAddress(
                  get(this.editing, "riskAddress", "")
                )}`
              }
            ]
          },
          {
            sectionTitle: "Previous Address",
            showSection:
              this.editing &&
              this.editing.quoteApplication &&
              this.editing.quoteApplication.previousAddress &&
              !!this.editing.quoteApplication.previousAddress.houseNumber &&
              !!this.editing.quoteApplication.previousAddress.streetName &&
              !!this.editing.quoteApplication.previousAddress.city &&
              !!this.editing.quoteApplication.previousAddress.state &&
              !!this.editing.quoteApplication.previousAddress.zipCode,
            showDivider: true,
            sectionColumnSize: "8",
            information: [
              {
                informationColumnSize: "2",
                key: "",
                isArray: false,
                value: `${stringifyAddress(
                  get(this.editing, "quoteApplication.previousAddress", "")
                )}`
              }
            ]
          }
        ]
      };
      return results;
    },
    getCoApplicantData(): any[] {
      let results = [];
      for (
        let i = 0;
        i < get(this.editing, "quoteApplication.coApplicants.length");
        i++
      ) {
        results.push({
          sections: [
            {
              sectionTitle: "",
              showSection: true,
              showDivider: false,
              sectionColumnSize: "8",
              information: [
                {
                  informationColumnSize: "2",
                  key: "First Name",
                  isArray: false,
                  value: get(
                    this.editing,
                    "quoteApplication.coApplicants[i].firstName",
                    "N/A"
                  )
                },
                {
                  informationColumnSize: "2",
                  key: "Last Name",
                  isArray: false,
                  value: get(
                    this.editing,
                    "quoteApplication.coApplicants[i].lastName",
                    "N/A"
                  )
                },
                {
                  informationColumnSize: "2",
                  key: "Email",
                  isArray: false,
                  value: get(
                    this.editing,
                    "quoteApplication.coApplicants[i].email",
                    "N/A"
                  )
                }
              ]
            },
            {
              sectionTitle: "",
              showSection: true,
              showDivider: false,
              sectionColumnSize: "8",
              information: [
                {
                  informationColumnSize: "2",
                  key: "Date of Birth",
                  isArray: false,
                  value: getPHPAPIFormattedDate(
                    new Date(
                      get(
                        this.editing,
                        "quoteApplication.coApplicants[i].dateOfBirth"
                      )
                    )
                  )
                },
                {
                  informationColumnSize: "2",
                  key: "Marital Status",
                  isArray: false,
                  value: get(
                    this.editing,
                    "quoteApplication.coApplicants[i].maritalStatus"
                  )
                }
              ]
            }
          ]
        });
      }
      return results;
    },
    getMortgagesData(): any[] {
      let results = [];
      for (let i = 0; i < get(this.editing, "mortgages").length; i++) {
        results.push({
          sections: [
            {
              sectionTitle: "Mortgage #" + (i + 1),
              showSection: true,
              showDivider: false,
              sectionColumnSize: "8",
              information: [
                {
                  informationColumnSize: "2",
                  key: "Mortgage Name",
                  isArray: false,
                  value: get(this.editing.mortgages[i], "mortgageName")
                },
                {
                  informationColumnSize: "2",
                  key: "Address",
                  isArray: false,
                  value: `${get(
                    this.editing.mortgages[i],
                    "address",
                    ""
                  )}, ${get(this.editing.mortgages[i], "city", "")}, ${get(
                    this.editing.mortgages[i],
                    "state",
                    ""
                  )}, ${get(this.editing.mortgages[i], "zipCode", "")}`
                }
              ]
            }
          ]
        });
      }
      return results;
    },
    companyNumber(): number | null {
      return this.editing.isLegacy
        ? get(this.editing, "companyNumbers[0]", null)
        : get(this.editing, "ratingSelectedData.companyNumber", null);
    },
    policyNumber(): string | undefined {
      if (this.editing && this.editing.policyNumber) {
        return this.editing.policyNumber;
      }
      return undefined;
    },
    endorsements(): any[] {
      return get(this.editing, "endorsements[0]", {});
    }
  }
});
